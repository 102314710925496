import React, { useState } from "react"
import ContactForm from "../components/contact-form";
import { graphql } from 'gatsby';
import styled, { Box, up, css } from '@xstyled/styled-components'
import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import NavBack from '../components/nav-back'
import SEO from '../components/seo'

const SubTitle = styled.h5`
  ${up(
  'md',
  css`
      margin-bottom: 72rpx;
    `
)}
`

const ErrorMessage = styled.div`
    color: red;
    text-align:center;
`

export default function Contact({data, pageContext}) {
  let [ errorMsg, updateErrorMsg ] = useState("");
  const lowerLang = pageContext.lang.toLowerCase()
  let capLang = lowerLang.charAt(0).toUpperCase() + lowerLang.charAt(1)
  capLang = capLang === 'Zh' ? 'ZhHant' : capLang
  const translatedData = data[`all${capLang}Json`].edges[0].node
  const { contact } = translatedData
  const form = {
    action: `${process.env.GATSBY_CONTACT_URL ?? process.env.GATSBY_DRUPAL_BASE_URL}/webform_rest/submit`,
    method: "POST",
    token: process.env.GATSBY_API_TOKEN,
  }

  const displayError = (msg) => {
     updateErrorMsg(msg => (errorMsg = msg))
  }

  return (
      <Layout lang={pageContext}>
      <SEO title={contact.title}/>
      <Container>
        <Box row justifyContent="center">
          <Box col={{ xs: 1, md: 3 / 4, xl: 2 / 3 }}>
            <NavBack linkedText={translatedData.cta.back} />
            <h1>{contact.title}</h1>
            <SubTitle>{contact.subtitle}</SubTitle>
            <ContactForm
              data={contact}
              lang={lowerLang}
              form={form}
              setErrorMsg={updateErrorMsg}
            />
            <br/>
            <ErrorMessage>{errorMsg}</ErrorMessage>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allEnJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allEsJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allArJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allKoJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allBnJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allZhHantJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
  }
`
