import React, { useState } from 'react'
import styled, { css, up } from '@xstyled/styled-components'
import Button from '../components/button'
import ReCAPTCHA from 'react-google-recaptcha'

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 44rpx;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
`

const InputStyles = css`
  padding: 10rpx 20rpx;
  border: 1px solid rgba(19, 19, 19, 0.1);
  font-size: 15rpx;
  line-height: 30rpx;

  &::placeholder {
    color: rgba(19, 19, 19, 0.5);
  }

  &:focus,
  &:active {
    border-color: var(--ssy-black);
  }

  ${up(
  'md',
  css`
      padding: 24rpx 20rpx;
      font-size: 18rpx;
      line-height: 30rpx;
    `
)}
`

const StyledInput = styled.input`
  ${InputStyles}
`

const StyledTextArea = styled.textarea`
  ${InputStyles}
`

const StyledButton = styled(Button)`
  width: 100%;

  ${up(
  'sm',
  css`
      width: 405rpx;
    `
)}
`

const StyledRecaptcha = styled(ReCAPTCHA)`
  margin: 0 auto 44rpx;
`

const ContactForm = ({data, form, lang, setErrorMsg}) => {
  const [ formFields, setFormFields ] = useState({})
  const recaptchaRef = React.useRef()

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    e.persist()
    setFormFields(formFields => ({ ...formFields, [name]: value }))
  }

  const formSubmit = (e, form, fields) => {
    e.preventDefault();
    setErrorMsg('');

    const { action, token } = form
    const data = {
      webform_id: 'contact',
      email: fields.email,
      subject: fields.subject,
      message: fields.message,
    }

    recaptchaRef.current.execute()
    .then((recaptchaToken) => {
      data.recaptcha_token = recaptchaToken

      return fetch(action, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }

      console.dir(response)
      throw 'There was an error with your submission, please try again!'
    })
    .then(response => {
      if (response.sid) {
        window.location.href = '/thank-you'
      } else {
        console.dir(response)
        throw 'There was an error with your submission, please try again!'
      }
    })
    .catch(error => {
      console.error(error);
      setErrorMsg(error)
    })
  };

  return (
    <Form
      action={form.action}
      method={form.method}
      id="contact-form"
      onSubmit={form, e => formSubmit(e, form, formFields)}
    >
      <FormRow>
        <label htmlFor="email">{data.email.label}</label>
        <StyledInput
          name="email"
          id="email"
          type="email"
          placeholder={data.email.placeHolder}
          onChange={e => handleChange(e)}
          value={formFields.email}
          required
        />
      </FormRow>
      <FormRow>
        <label htmlFor="subject">{data.subject.label}</label>
        <StyledInput
          name="subject"
          id="subject"
          type="text"
          placeholder={data.subject.placeHolder}
          onChange={e => handleChange(e)}
          value={formFields.text}
          required
        />
      </FormRow>
      <FormRow>
        <label htmlFor="message">{data.message.label}</label>
        <StyledTextArea
          name="message"
          id="message"
          cols="30"
          rows="10"
          placeholder={data.message.placeHolder}
          onChange={e => handleChange(e)}
          value={formFields.message}
          required
        />
      </FormRow>
      <FormRow>
        <StyledRecaptcha
          ref={recaptchaRef}
          hl={lang}
          sitekey={`${process.env.GOOGLE_RECAPTCHA_SITE_KEY}`}
          size="invisible"
          // onChange={handleSubmit}
        />
        <StyledButton
          ariaLabel="submit message"
          type="submit"
          text={data.submit}
          center />
      </FormRow>
    </Form>
  )
};

export default ContactForm
